// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: "AIzaSyD7swFd2kManMbeaZIv_AmqfDZv4EU3ASw",
    authDomain: "rtc-dev-api.firebaseapp.com",
    databaseURL: "https://rtc-dev-api-default-rtdb.firebaseio.com",
    projectId: "rtc-dev-api",
    storageBucket: "rtc-dev-api.appspot.com",
    messagingSenderId: "895000127376",
    appId: "1:895000127376:web:f6d475c2dddd2c06a47515",
    measurementId: "G-9ZQ9YS2N3M"
  },
  remoteConfig: 'PROD_CONFIG',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
