import { Component } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/analytics';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  year = moment().format('YYYY');
  faHome = faHome;
  isCollapse = true;
  constructor() {
    firebase.initializeApp(environment.firebaseConfig);
    firebase.analytics();
    /* firebase.functions().useFunctionsEmulator('http://localhost:5003'); */
  }


  toggleState() {
    this.isCollapse = !this.isCollapse;
  }

}
