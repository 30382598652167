import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../shared/auth.service';

@Component({
  selector: 'app-foundation',
  templateUrl: './foundation.component.html',
  styleUrls: ['./foundation.component.css']
})
export class FoundationComponent implements OnInit {

  foundation: any = {};
  loading: boolean = false;
  passwordConfirm: string;
  user: any = null;
  step: number = 1;
  date = new Date;
  months: any = ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];

  constructor(public activeModal: NgbActiveModal, public authService: AuthService) { }

  ngOnInit(): void { }

  register() {
    if (this.foundation.password == this.passwordConfirm) {
      this.loading = true;
      this.foundation.enabled = false;
      this.authService.SignUpFoundation(this.foundation).then((result: any) => {
        this.user = result.user;
        this.user.updateProfile({
          displayName: this.foundation.name
        }).then(() => {
          this.authService.SetUserData(result.user, this.foundation);
          this.sendEmailVerification();
          this.loading = false;
        })
      }, () => {
        this.loading = false;
        alert('Error al registrar la cuenta, intente mas tarde.')
      });
    } else {
      alert('las contraseñas no coinciden.');
    }
  }

  continue() {
    this.step = 2;
  }
  agree() {
    this.step = 3;
  }
  cancel() {
    if(this.step == 3) {
      this.step = 2;
    } else {
      this.step = 1;
    }
  }
  sendEmailVerification() {
    this.user.sendEmailVerification();
  }

}
