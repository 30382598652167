import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class SwalAlertsService {
  constructor() { }

  showAlert(msj: string, icon: SweetAlertIcon, title = '') {
    const swalBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-warning',
      },
      buttonsStyling: false
    });
    swalBootstrapButtons.fire({
      title,
      text: msj,
      icon,
      confirmButtonText: 'Aceptar',
    });
  }

  deleteAlert() {
    const swalBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    });
    return swalBootstrapButtons.fire({
      title: '¿Estás seguro de eliminar?',
      text: 'Está acción no se puede revertir',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Si, eliminar',
      cancelButtonText: 'Cancelar',
      reverseButtons: true
    });
  }

  loadingAlert(msj: string) {
    Swal.fire({
      title: msj,
      didOpen: () => {
        Swal.showLoading();
      }
    });
  }

  hideLoading() {
    Swal.close();
    Swal.hideLoading();
  }

}
