import { Component, OnDestroy, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'; // Renderer2
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CustomValidators } from '../../customValidators';
import { faGift } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { SwalAlertsService } from '../../shared/sweetalert.service';
import { Subscription } from 'rxjs';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-birthday',
  templateUrl: './birthdays.component.html',
  styleUrls: ['./birthdays.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BirthdaysComponent implements OnInit, OnDestroy {
  @ViewChild('content') private content: TemplateRef<any>;
  subscription = new Subscription();
  inputCode: string;
  birthdayCode: string;
  amount = 0;
  faGift = faGift;
  faHeart = faHeart;
  transactions: any[] = [];
  birthday: any = {};
  fundation: any = {};
  loading = false;
  giftBag: any = [];
  termsConditions = false;
  modal: NgbModalRef = null;
  selectedDonation: any = {};
  giftForm: FormGroup;
  mode: 'List' | 'Other' = 'List';
  totalDonations: any;
  transbank: any;
  config: any;
  constructor(private router: Router,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private sw: SwalAlertsService,
              private route: ActivatedRoute,
              private httpClient: HttpClient) {
    this.giftForm = this.fb.group({
      name: ['', Validators.compose([CustomValidators.required])],
      email: ['', Validators.compose([CustomValidators.required, CustomValidators.email])],
      msg: ['', Validators.compose([CustomValidators.required])]
    });
  }

  async ngOnInit() {
    this.inputCode = this.route.snapshot.paramMap.get('birthdayCode');
    await firebase.remoteConfig().fetchAndActivate();
    const config: any = firebase.remoteConfig().getAll();
    this.config = JSON.parse(config[environment.remoteConfig]._value);
    if (this.inputCode) {
      this.loading = true;
      this.birthdayCode = this.inputCode;
      this.birthday = await firebase.firestore().collection('birthday')
        .where("private_code", "==", this.birthdayCode).get().then(snapshot => {
          if (!snapshot.empty) {
            return {id: snapshot.docs[0].id,...snapshot.docs[0].data()};
          }
          return null;
        });
      if (this.birthday) {
        this.fundation = await this.getFoundation();
        this.subscription.add(firebase.firestore().collection("transbank").where("birthday", "==", this.birthday.id)
          .where("transaction.response_code", "==", 0).onSnapshot((snapshot) => {
            if (!snapshot.empty) {
              this.transactions = snapshot.docs.map(doc => {
                const data = doc.data();
                return {id: doc.id,  message: data.message || '', sended: data.sended || false, ...data};
              });
              console.log(this.transactions)
              this.totalDonations = this.transactions.reduce((acc, cur) => acc + cur.amount, 0);
            }
            this.loading = false;
        }));
      } else {
        this.router.navigateByUrl('/birthdays');
        this.sw.showAlert(`No encontramos el cumpleaños código "${this.birthdayCode.trim()}"`, 'warning');
        this.loading = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  getFoundation() {
    return new Promise((resolve, reject) => {
      console.log(this.birthday)
      this.sendRequest('get','FUNDATION', this.birthday.foundation, 'id').subscribe(
        fundation => {
          this.fundation = fundation;
          this.fundation.gifts.sort(((a, b) => parseFloat(a.price) - parseFloat(b.price)));
          for (const gift of this.fundation.gifts) {
            gift.quantity = 0;
          };
          resolve(this.fundation);
        });
    });
  }

  customDonation(inputValue: string) {
    const donation = inputValue.replace(/([\.])+/g, '');
    this.amount = Number(donation);
  }

  setCode() {
    this.router.navigateByUrl(`/birthdays/${this.inputCode}`);
  }

  sendRequest(method, endpoint: string, value?: string, selector?: string): Observable<any> {
    const params = value ? '?' + selector + '=' + value : '';
    return this.httpClient[method]<any>(this.config.projectAPIURL +'/' + endpoint + params);
  }
  /* DEV: http://localhost:5003/rtc-dev-api/us-central1/transactions */
  /* PROD: https://us-central1-rtc-dev-api.cloudfunctions.net/transactions */
  sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>( this.config.projectAPIURL + '/transactions', data);
  }

  showDonation(donation) {
    this.selectedDonation = donation;
    this.modal = this.modalService.open(this.content, { size: 'lg' });
  }

  async sendMessage() {
    try {
      this.loading = true;
      const response: any = await new Promise((resolve, reject) => {
        this.sendRequest('post', 'SEND_MAIL', JSON.stringify(this.selectedDonation), 'transaction').subscribe(
          response => resolve(response),
          (error) => reject(error));
      });
      // response.ok
      if (response.ok) {
        this.sw.showAlert('Mensaje enviado', 'success');
        this.modal.close();
      } else {
        this.sw.showAlert('Error al enviar el mensaje: ' + response.message, 'error');
      }
      this.loading = false;
    } catch (e) {
      this.sw.showAlert('Error al enviar el mensaje', 'error');
      this.loading = false;
    }

  }
}
