import { CustomValidators } from './../../customValidators';
import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { faFacebook, faInstagram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as firebase from 'firebase';
import { environment } from '../../../environments/environment';
import { toHtml } from '@fortawesome/fontawesome-svg-core';
import { AngularFireAuth } from '@angular/fire/auth';


@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
  list = true;
  done = false;
  final = false;
  step = 1;
  urlBirthdays = 'https://rtc-dev-api.web.app/birthday/';
  selected = false;
  loading = true;
  foundation: any;
  foundations: any = [];
  modal: NgbModalRef = null;
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faWhatsapp = faWhatsapp;
  faTwitter = faTwitter;
  faCalendar = faCalendar;
  faClipboard = faClipboard;
  areas: any[] = [];
  comunas: any[] = [];
  regions: any[] = [];
  userForm: FormGroup;
  countries: any = [];
  user: any = null;
  activeFundation = null;
  gender = null;
  genders = [
    {name: 'Hombre', letter: 'o'},
    {name: 'Mujer', letter: 'a'},
    {name: 'Otro', letter: '@'},
  ];
  method = null;
  methods = [
    {name: 'Recibi un codigo de cumpleaños'},
    {name: 'Por redes sociales'},
    {name: 'Otro (especificar)'},
  ]
  codes: any = {
    code: '',
    private_code: ''
  };
  actualYear = new Date().getFullYear();
  @ViewChild('content') private content: TemplateRef<any>;
  config = null;
  constructor(
    private httpClient: HttpClient,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private http: HttpClient) {
    this.userForm = this.fb.group({
      name: ['', Validators.compose([CustomValidators.required])],
      last_name: ['', Validators.compose([CustomValidators.required])],
      phone_number: ['', Validators.compose([CustomValidators.required])],
      email: ['', Validators.compose([CustomValidators.required, CustomValidators.email])],
      gender: [null, Validators.compose([CustomValidators.required])],
      nacionality: [null, Validators.compose([CustomValidators.required])],
      country: [null, Validators.compose([CustomValidators.required])],
      place: [null, Validators.compose([])],
      method: [null, Validators.compose([])],
      regions: [null, Validators.compose([])],
      birth_date: ['', Validators.compose([CustomValidators.required])],
      celebration_date: ['', Validators.compose([CustomValidators.required])],
      specifying: ['', Validators.compose([])]
    });
  }

  async ngOnInit() {
    this.loading = true;
    await firebase.remoteConfig().fetchAndActivate();
    const config: any = firebase.remoteConfig().getAll();
    this.config = JSON.parse(config[environment.remoteConfig]._value);
    this.urlBirthdays = this.config.projectURL + '/birthday/';
    this.http.get('/assets/data/comunas.json').subscribe((data: any) => {
      let regiones = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (regiones.indexOf(element.region) === -1) {
          regiones.push(element.region);
        }
      }
      console.log(regiones);
      this.regions = regiones;
      this.comunas = data;
      this.areas = data;
    });
    this.http.get('/assets/data/paises.json').subscribe((data: any) => {
      this.countries =  data;
    });
    this.sendGetRequest().subscribe(
      data => {
        console.log(data);
        this.foundations = data;
        this.loading = false;
      }
    );

  }

  limpiar(paises){
    this.userForm.controls['regions'].setValue(null);
    this.userForm.controls['place'].setValue(null);
  }

//esta funcion se debe ejecutar cuando el valor del select de regiones cambie.
//esta funcion debe setear nuevos valores en el array de comunas.7

  cambiarValores(region) {
    let comunas = [];
    for (let index = 0; index < this.comunas.length; index++) {
      const element = this.comunas[index];
      if(element.region === region) {
        comunas.push(element);
      }
    }

    this.areas = comunas;
  }

 

  async register() {
    this.activeFundation = this.foundation.name;
    this.modal = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
    return this.modal.result.then((x) => x);
  }

  confirm() {
    return new Promise((resolve, reject) => {
      this.loading = true;
      const payload = { ...this.user, foundation: this.foundation?.id, foundationName: this.foundation?.name };
      this.sendPostRequest(payload).subscribe(
        res => {
          this.codes['code'] = res.code;
          this.codes['private_code'] = res.private_code;
          this.loading = false;
          resolve(true);
        }, (err) => {
          console.log(err.message);
          this.loading = false;
        }, () => {
          this.loading = false;
          resolve(true);
        }
      );
    });
  }

  async select(foundation) {
    this.foundation = foundation;
    const resp: boolean = await this.register();
    if (resp) {
      await this.confirm();
      this.step += 1;
    }
  }

  nextStep(mode = null) {
    this.step += 1;
    if (mode === 'setUser') {
      console.log (this.userForm.value);
      this.user = this.userForm.value;
      this.gender = this.genders.filter((x) => x.name === this.user.gender)[0];
    }
  }

  sendGetRequest(): Observable<any> {
    return this.httpClient.get<any>(this.config.projectAPIURL + '/FUNDATION');
  }
  

  sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>(this.config.projectAPIURL + '/BIRTHDAY', data);
  }

  copyToken(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
  }

}
