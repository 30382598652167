import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TermConditionsComponent } from './components/term-conditions/term-conditions.component';
import { BirthdayComponent } from './components/birthday/birthday.component';
import { ContactComponent } from './components/contact/contact.component';
import { FoundationsComponent } from './components/foundations/foundations.component';
import { HistoryComponent } from './components/history/history.component';
import { HomeComponent } from './components/home/home.component';
import { TeamComponent } from './components/team/team.component';
import { HowComponent } from './components/how/how.component';
import { BirthdaysComponent } from './components/birthdays/birthdays.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { SignupComponent } from './components/signup/signup.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent },
  { path: 'birthday', component: BirthdayComponent },
  { path: 'birthday/:birthdayCode', component: BirthdayComponent },
  { path: 'birthdays', component: BirthdaysComponent },
  { path: 'birthdays/:birthdayCode', component: BirthdaysComponent },
  { path: 'foundations', component: FoundationsComponent },
  { path: 'team', component: TeamComponent },
  { path: 'termConditions', component: TermConditionsComponent },
  { path: 'history', component: HistoryComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'how', component: HowComponent },
  { path: 'invoice', component: InvoiceComponent },
  { path: 'signup', component: SignupComponent },
  { path: '', redirectTo: '/home', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
