import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  contactName: string;
  contactMail: string;
  contactMsg: string;
  subjectMsg: string;

  constructor() { }

  ngOnInit(): void {
    this.contactName = '';
    this.contactMail = '';
    this.contactMsg = '';
    this.subjectMsg = '';
  }

  submitContactForm() {
    alert('Mensaje enviado! te responderemos a la brevedad.');
    this.contactName = '';
    this.contactMail = '';
    this.contactMsg = '';
    this.subjectMsg = '';
  }

}
