import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { BrowserModule } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {


  loading: boolean;
  invoice: any;

  constructor(private route: ActivatedRoute, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let invoice = params['i'];
      if(invoice) {
        this.loading = true;
        this.sendGetRequest(invoice).subscribe(
          data => {
            console.log(':::::::INVOICE DATA::::::::', data);
            this.invoice = data;
            this.loading = false;
          }
        );
      }
    });
  }

  sendGetRequest(data: any): Observable<any> {
    return this.httpClient.get<any>('https://us-central1-rtc-dev-api.cloudfunctions.net/invoice?id='+data);
  }

}
