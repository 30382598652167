import { Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SignupComponent } from '../signup/signup.component';
import { SigninComponent } from '../signin/signin.component';
import { Router, ActivatedRoute } from '@angular/router';
import { FoundationComponent } from '../foundation/foundation.component';
import { DataService } from '../../shared/data.service';;
import { Observable, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  title = 'regalatucumple';
  closeResult = '';
  birthdayCode = '';
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/1600/400`);

  birthdays: any = [{
      code: '1',
      name: 'Ignacio',
      lastname: 'Lagos',
      fundation: 'Infocap',
      img: '../assets/ignacio.png'
    },
    {
      code: '2',
      name: 'David',
      lastname: 'Acevedo',
      fundation: 'Infocap',
      img: '../assets/david.png'
    },
    {
      code: '3',
      name: 'Francisco',
      lastname: 'Santa Maria',
      fundation: 'Infocap',
      img: '../assets/francisco.jpeg'
    }
  ];

  fundations: any = [];

  destroy$: Subject<boolean> = new Subject<boolean>();
  invoice: any;


  constructor(private modalService: NgbModal, private router: Router, private route: ActivatedRoute, private dataService: DataService, private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let invoice = params['i'];
      if(invoice) {
        /*
        this.dataService.sendGetRequest('invoice', { id: invoice }).pipe(takeUntil(this.destroy$)).subscribe((data: any[])=>{
          console.log(':::::::INVOICE DATA::::::::', data);
          this.invoice = data;
        })
        */
        this.sendGetRequest(invoice, 'invoice').subscribe(
          data => {
            console.log(':::::::INVOICE DATA::::::::', data);
            this.invoice = data;
          }
        );
      }
      this.sendGetRequest({}, 'FUNDATION').subscribe(
        data => {
          console.log('FUNDATION', data);
          this.fundations = data;
        }
      );
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    // Unsubscribe from the subject
    this.destroy$.unsubscribe();
  }

  openModal(content) {
    this.modalService.open(SignupComponent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  signUp() {
    this.modalService.open(SignupComponent, { scrollable: true, size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  signin() {
    this.modalService.open(SigninComponent, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  signUpFoundation() {
    this.modalService.open(FoundationComponent, { scrollable: true, size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getBirthdayData(birthdayCode) {
    this.router.navigate(['/birthday', birthdayCode])
  }

  sendGetRequest(data: any, res: string): Observable<any> {
    const url = 'https://us-central1-rtc-dev-api.cloudfunctions.net/'+res+(res=='invoice'?'?id='+data: '');
    return this.httpClient.get<any>(url);
  }

  register() {
    alert("register");
  }

  donate() {
    alert("donate");
  }


}
