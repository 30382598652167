import { Component, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core'; // Renderer2
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {CustomValidators} from '../../customValidators';
import {SwalAlertsService} from '../../shared/sweetalert.service';
import firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-birthday',
  templateUrl: './birthday.component.html',
  styleUrls: ['./birthday.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class BirthdayComponent implements OnInit {
  @ViewChild('content') private content: TemplateRef<any>;
  inputCode: string;
  birthdayCode: string;
  amount = 0;
  transbank: any = {};
  birthday: any = {};
  fundation: any = {};
  loading = false;
  giftBag: any = [];
  termsConditions = false;
  modal: NgbModalRef = null;
  giftForm: FormGroup;
  mode: 'List' | 'Other' = 'List';
  config: any;
  constructor(private router: Router,
              private modalService: NgbModal,
              private fb: FormBuilder,
              private sw: SwalAlertsService,
              private route: ActivatedRoute,
              private httpClient: HttpClient) {
    this.giftForm = this.fb.group({
      name: ['', Validators.compose([CustomValidators.required])],
      email: ['', Validators.compose([CustomValidators.required, CustomValidators.email])],
      msg: ['', Validators.compose([CustomValidators.required])]
    });
  }

  async ngOnInit() {
    await firebase.remoteConfig().fetchAndActivate();
    const config: any = firebase.remoteConfig().getAll();
    this.config = JSON.parse(config[environment.remoteConfig]._value);
    this.inputCode = this.route.snapshot.paramMap.get('birthdayCode');
    if (this.inputCode) {
      this.getData(this.inputCode);
    }
  }

  async confirm() {
      this.modal = this.modalService.open(this.content, {ariaLabelledBy: 'modal-basic-title'});
      return await this.modal.result.then((x) => x);
  }

  async donate() {
    const confirm = await this.confirm();
    if (confirm) {
      const donation = {
        birthday: this.birthdayCode,
        amount: this.amount,
        contact: confirm
      };
      this.loading = true;
      this.sendPostRequest(donation).subscribe(
        res => {
          this.transbank = res;
          const form = document.createElement('form');
          form.action = res.url;
          form.method = 'POST';
          const tokenWs = document.createElement('input');
          tokenWs.setAttribute('type', 'hidden');
          tokenWs.setAttribute('name', 'token_ws');
          tokenWs.setAttribute('value', res.token);
          form.appendChild(tokenWs);
          document.getElementsByTagName('body')[0].appendChild(form);
          form.submit();
          this.loading = false;
        }, (err) => {
          console.log(err);
          this.sw.showAlert(`Error ${err.message}`, 'warning');
          this.loading = false;
        }
      );
    }
  }

  recalculate() {
    let total = 0;
    this.fundation.gifts.forEach(element => {
      total += element.price * element.quantity;
      if (element.quantity > 0) {
        if (this.giftBag.indexOf(element) === -1) {
          this.giftBag.push(element);
        } else {
          this.giftBag[this.giftBag.indexOf(element)].quantity = element.quantity;
        }
      }
    });
    this.amount = total;
  }

  clickGift(gift: any) {
    this.addGift(gift);
  }

  addGift(gift: any) {
    // this.giftBag.push(gift);
    gift.quantity += 1;
    this.recalculate();
  }

  removeGift(gift) {
    if (gift.quantity > 0) {
      gift.quantity -= 1;
    }
    this.recalculate();
    // const index = this.giftBag.indexOf(gift);
    // this.giftBag.splice(index, 1);
  }

  getData(birthdayCode: string) {
    this.loading = true;
    this.sendGetRequest('BIRTHDAY', birthdayCode.trim(), 'code').subscribe(
      birthday => {
        if (birthday?.finishedAt) {
          this.sw.showAlert('Esté cumpleaños ya ha finalizado', 'warning');
          this.birthdayCode = null;
          this.loading = false;
          return;
        }
        if (birthday) {
          this.birthdayCode = birthdayCode;
          this.birthday = birthday;
          this.sendGetRequest('FUNDATION', this.birthday.foundation, 'id').subscribe(
            fundation => {
              this.fundation = fundation;
              this.fundation.gifts.sort(((a, b) => parseFloat(a.price) - parseFloat(b.price)));
              for (const gift of this.fundation.gifts) {
                gift.quantity = 0;
              }
              console.log(this.fundation);
              this.loading = false;
            });
        } else {
          this.sw.showAlert(`No encontramos el cumpleaños código "${birthdayCode.trim()}"`, 'warning');
          this.loading = false;
        }
    });
  }

  customDonation(inputValue: string) {
    const donation = inputValue.replace(/([\.])+/g, '');
    this.amount = Number(donation);
  }

  changeMode() {
    this.mode = this.mode === 'List' ? 'Other' : 'List';
    this.amount = 0;
    this.giftBag = [];
    if (this.mode === 'List') {
      this.recalculate();
    }
  }

  setCode() {
    this.getData(this.inputCode);
  }

  sendGetRequest(endpoint: string, value?: string, selector?: string): Observable<any> {
    const params = value ? '?' + selector + '=' + value : '';
    return this.httpClient.get<any>(this.config.projectAPIURL +'/' + endpoint + params);
  }
  /* DEV: http://localhost:5003/rtc-dev-api/us-central1/transactions */
  /* PROD: https://us-central1-rtc-dev-api.cloudfunctions.net/transactions */
  sendPostRequest(data: any): Observable<any> {
    return this.httpClient.post<any>(this.config.projectAPIURL + '/transactions', data);
  }

}
