import { Injectable, NgZone } from '@angular/core';
//import { User } from "./user";
import { auth } from 'firebase/app';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from "@angular/router";

@Injectable({
    providedIn: 'root'
})

export class AuthService {
    userData: any; // Save logged in user data

    constructor(
        public afs: AngularFirestore,   // Inject Firestore service
        public afAuth: AngularFireAuth, // Inject Firebase auth service
        public router: Router,
        public ngZone: NgZone // NgZone service to remove outside scope warning
    ) {
        /*
        this.afAuth.authState.subscribe(user => {
            if (user) {
                this.userData = user;
            }
        })
        */
    }

    // Sign in with email/password
    /*
    SignIn(email, password) {
        return this.afAuth.signInWithEmailAndPassword(email, password)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(['dashboard']);
                });
                this.SetUserData(result.user);
            }).catch((error) => {
                window.alert('Tu correo o password son incorrectos.');
            })
    }*/

    SignUpFoundation(foundation) {
        return this.afAuth.createUserWithEmailAndPassword(foundation.email, foundation.password);
    }

    // Sign up with email/password
    SignUp(foundation) {
        return this.afAuth.createUserWithEmailAndPassword(foundation.email, foundation.password)
            .then((result) => {
                /* Call the SendVerificaitonMail() function when new user sign 
                up and returns promise */
                result.user.updateProfile({
                    displayName: foundation.name
                }).then(() => {
                    this.SetUserData(result.user, foundation);
                    return result.user;
                    //this.SendVerificationMail();
                })
            }).catch((error) => {
                window.alert('Tu correo o password no son válidos.');
                console.log(error.message)
            })
    }

    // Send email verfification when new user sign up
    /*
    SendVerificationMail() {
        return this.afAuth.currentUser.then((user) => {
            user.sendEmailVerification();
        })
    }
    */

    // Reset Forggot password
    /*
    ForgotPassword(passwordResetEmail) {
        return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
            .then(() => {
                window.alert('Se envio un correo para restablecer tu password, revisa tu inbox.');
            }).catch((error) => {
                console.log(error)
                window.alert('No pudimos restablecer tu contraseña.');
            })
    }
    */

    // Returns true when user is looged in and email is verified
    /*
    get isLoggedIn(): boolean {
        const user = JSON.parse(localStorage.getItem('user'));
        return (user !== null && user.emailVerified !== false) ? true : false;
    }

    // Sign in with Google
    GoogleAuth() {
        return this.AuthLogin(new auth.GoogleAuthProvider());
    }

    // Auth logic to run auth providers
    AuthLogin(provider) {
        return this.afAuth.signInWithPopup(provider)
            .then((result) => {
                this.ngZone.run(() => {
                    this.router.navigate(['dashboard']);
                })
                this.SetUserData(result.user);
            }).catch((error) => {
                window.alert(error)
            })
    }*/

    //Setting up user data when sign in with username/password, 
    //sign up with username/password and sign in with social auth  
    //provider in Firestore database using AngularFirestore + AngularFirestoreDocument service 
    SetUserData(user, foundation) {
        const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
        debugger;
        const userData: any = {
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            photoURL: user.photoURL,
            emailVerified: user.emailVerified,
            ...foundation
        }
        return userRef.set(userData, {
            merge: true
        })
    }

    /*
    // Sign out 
    SignOut() {
        return this.afAuth.signOut().then(() => {
            localStorage.removeItem('user');
            this.router.navigate(['sign-in']);
        })
    }
    */

}