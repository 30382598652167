import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { AngularFireModule } from '@angular/fire';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { environment } from '../environments/environment';

import { AuthService } from './shared/auth.service'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule, NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';

import { SignupComponent } from './components/signup/signup.component';
import { SigninComponent } from './components/signin/signin.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BirthdayComponent } from './components/birthday/birthday.component';
import { BirthdaysComponent } from './components/birthdays/birthdays.component';
import { HomeComponent } from './components/home/home.component';
import { TeamComponent } from './components/team/team.component';
import { HistoryComponent } from './components/history/history.component';
import { FoundationsComponent } from './components/foundations/foundations.component';
import { ContactComponent } from './components/contact/contact.component';
import { FoundationComponent } from './components/foundation/foundation.component';
import { HowComponent } from './components/how/how.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { TermConditionsComponent } from './components/term-conditions/term-conditions.component';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
import localeEsCL from '@angular/common/locales/es-CL';
registerLocaleData(localeEsCL, 'es-Cl');


@NgModule({
  declarations: [
    AppComponent,
    SignupComponent,
    SigninComponent,
    BirthdayComponent,
    BirthdaysComponent,
    HomeComponent,
    TeamComponent,
    HistoryComponent,
    FoundationsComponent,
    ContactComponent,
    FoundationComponent,
    HowComponent,
    InvoiceComponent,
    TermConditionsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPopoverModule,
    NgSelectModule,
    FontAwesomeModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    NgxMaskModule.forRoot(),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    HttpClientModule
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
