import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-foundations',
  templateUrl: './foundations.component.html',
  styleUrls: ['./foundations.component.css']
})
export class FoundationsComponent implements OnInit {

  loading: boolean = false;

  fundations: any = [];

  constructor(private httpClient: HttpClient) { }

  ngOnInit(): void {
    this.loading = true;
    this.sendGetRequest().subscribe(
      data => {
        this.fundations = data;
        this.loading = false;
      }
    );
  }

  sendGetRequest(): Observable<any> {
    return this.httpClient.get<any>('https://us-central1-rtc-dev-api.cloudfunctions.net/FUNDATION');//?id='+data
  }


  












}
